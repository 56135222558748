import { Tooltip, Popover, Tab } from './_bootstrap';
import Mmenu from 'mmenu-js';
import GLightbox from 'glightbox';

window.addEventListener('load', () => {
    initializeLightbox();
    initializeBackButtonReload();
});

document.addEventListener('DOMContentLoaded', () => {
    initializePageFadeEffect();
    initializeMmenu();
    initializeFadeIn();
    initializeBootstrapJs();
    copySidebarOnModal();
    initializeDropdownOverlay();
    initializeTabQueryNavigation();
});

function initializeTabQueryNavigation() {
    const tabTriggerElements = document.querySelectorAll('[data-bs-toggle="tab"]');

    // 1. Beim Klick auf einen Tab den Query-Parameter aktualisieren
    tabTriggerElements.forEach((triggerEl) => {
        triggerEl.addEventListener('shown.bs.tab', function () {
            const tabId = triggerEl.getAttribute('href').substring(1); // '#' entfernen
            if (tabId) {
                const url = new URL(window.location);
                url.searchParams.set('tab', tabId); // Query-Parameter setzen
                history.replaceState(null, '', url);
            }
        });
    });

    // 2. Dynamisch den aktiven Tab ermitteln
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab = urlParams.get('tab') || getFirstTabId();

    const tabTriggerEl = document.querySelector(`[href="#${activeTab}"]`);
    if (tabTriggerEl) {
        const tab = new Tab(tabTriggerEl);
        tab.show();
    }
}

// Dynamisch den ersten Tab auslesen, ohne Annahmen über IDs
function getFirstTabId() {
    const firstTabLink = document.querySelector('[data-bs-toggle="tab"]');
    if (firstTabLink) {
        // Den Wert von href direkt verwenden und das '#' entfernen
        return firstTabLink.getAttribute('href').substring(1);
    }
    return null;
}

function initializePageFadeEffect() {
    // Einblenden der Seite beim Laden
    window.addEventListener('pageshow', () => {
        const sitePreloader = document.querySelector('.site-preloader');
        const pageContent = document.querySelector('.page');
        if (pageContent) {
            pageContent.classList.remove('fade-out');
            pageContent.classList.add('fade-in');
            centerActiveLink();
        }
        if (sitePreloader) {
            sitePreloader.classList.remove('fade-in');
            sitePreloader.classList.add('fade-out');
        }
    });

    function handlePageUnload() {
        const pageContent = document.querySelector('.page');
        if (pageContent) {
            pageContent.classList.remove('fade-in');
            pageContent.classList.add('fade-out');
        }
        const sitePreloader = document.querySelector('.site-preloader');
        if (sitePreloader) {
            sitePreloader.classList.remove('fade-out');
            sitePreloader.classList.add('fade-in');
        }
    }

    window.addEventListener('pagehide', handlePageUnload);
    window.addEventListener('beforeunload', handlePageUnload); // weiterhin für Desktop
}

function centerActiveLink() {
    const horizontalScrollNav = document.querySelectorAll('.scrolling-wrapper');
    
    horizontalScrollNav.forEach(wrapper => {
        // Event Listener für Klick-Event
        wrapper.addEventListener('click', (event) => {
            const clickedElement = event.target.closest('a');
            if (clickedElement) {
                const navLinks = wrapper.querySelectorAll('a');
                navLinks.forEach(lnk => lnk.classList.remove('active', 'is_active'));
                clickedElement.classList.add('active', 'is_active');
                
                // Zentriere den aktiven Link beim Klick
                clickedElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        });

        // Zentriere den aktiven Link beim Laden der Seite
        const activeLink = wrapper.querySelector('.active') || wrapper.querySelector('.is_active');
        if (activeLink) {
            activeLink.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
        }

        // Event Listener für Fenstergrößenänderung
        window.addEventListener('resize', () => {
            const activeLink = wrapper.querySelector('.active') || wrapper.querySelector('.is_active');
            if (activeLink) {
                activeLink.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        });
    });
}

function initializeFadeIn() {
    const elementsFade = document.querySelectorAll('.transition-fade');
    elementsFade.forEach(element => {
        element.classList.add('fade-in');
    });
}

function initializeBootstrapJs() {
    document.querySelectorAll('[data-bs-toggle="tooltip"], [data-bs-toggle="popover"]').forEach(element => {
        if (element.getAttribute('data-bs-toggle') === 'tooltip') {
            new Tooltip(element);
        } else if (element.getAttribute('data-bs-toggle') === 'popover') {
            new Popover(element);
        }
    });
}

function initializeMmenu() {
    const mmenuElement = document.querySelector('#mmenu');
    if (mmenuElement) {
        new Mmenu('#mmenu', {
            slidingSubmenus: true,
            theme: 'light',
            setSelected: {
                hover: true,
                parent: true,
            },
            navbar: {
                add: true,
                title: '<i class="fa-solid fa-house"></i>',
                titleLink: 'none',
            },
            scrollBugFix: {
                fix: true
            },
            offCanvas: {
                position: 'left-front'
            },
            language: 'de',
            navbars: [
                {
                    position: 'top',
                    content: [
                        'prev',
                        '<a class="mm-menu-logo" href="/"><img src="/themes/custom/pax_radix/logo.svg" width="120" height="33" alt="MyPaX" /></a>',
                        'breadcrumbs',
                        'close'
                    ]
                }
            ]
        }, {
            offCanvas: {
                page: {
                    selector: '.dialog-off-canvas-main-canvas'
                }
            }
        });
    }
}

function copySidebarOnModal() {
    const sidebarContent = document.getElementById('sidebarContent');
    const modalSidebarContent = document.getElementById('modalSidebarContent');
    const sidebarRightModal = document.getElementById('sidebarRightModal');

    if (sidebarContent && modalSidebarContent && sidebarRightModal) {
        sidebarRightModal.addEventListener('show.bs.modal', () => {
            if (modalSidebarContent.innerHTML === '') {
                modalSidebarContent.innerHTML = sidebarContent.innerHTML;
            }
        });
    }
}

// BUGFIX Bootstrap modal appearing under backdrop
document.addEventListener('show.bs.modal', (event) => {
    const modal = event.target;
    document.body.appendChild(modal);
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
        modal.style.zIndex = parseInt(window.getComputedStyle(backdrop).zIndex, 10) + 1;
    }
    modal.focus();
});

function initializeLightbox() {
    // Initialisiere die erste Lightbox mit spezifischen Einstellungen
    const lightbox = GLightbox({
        loop: false,
        touchNavigation: true,
        moreText: 'Mehr anzeigen',
        moreLength: 128,
        openEffect: 'zoom',
        closeEffect: 'zoom',
        descPosition: 'right',
    });
}

function initializeDropdownOverlay() {
    let el_overlay = null;
    let openDropdownCount = 0;

    document.body.addEventListener('shown.bs.dropdown', (event) => {
        openDropdownCount++;
        if (openDropdownCount === 1) {
            // Nur wenn dies der erste geöffnete Dropdown ist, Overlay erzeugen
            el_overlay = document.createElement('span');
            el_overlay.className = 'screen-darken';
            const pageWrapper = document.querySelector('#page-wrapper');
            if (pageWrapper) {
                pageWrapper.appendChild(el_overlay);
            }
        }
    });

    document.body.addEventListener('hide.bs.dropdown', () => {
        // Nach einer kleinen Verzögerung prüfen, ob noch ein Dropdown offen ist
        // Die Verzögerung ist wichtig, um sicherzustellen, dass der aktuelle Dropdown
        // wirklich geschlossen wurde und ggf. ein anderer geöffnet ist.
        setTimeout(() => {
            // Aktualisiere die Anzahl offener Dropdowns, indem geprüft wird,
            // wie viele Dropdown-Menüs noch angezeigt werden
            openDropdownCount = document.querySelectorAll('.dropdown-menu.show').length;
            
            if (openDropdownCount === 0 && el_overlay) {
                // Wenn keine Dropdowns mehr offen sind, Overlay entfernen
                el_overlay.parentNode.removeChild(el_overlay);
                el_overlay = null;
            }
        }, 50);
    });
}


document.body.addEventListener('mousedown', (e) => {
    const target = e.target.closest('.btn, .ripple-init, .ripple-wrapper');
    if (target && target.tagName !== 'INPUT') {
        createRipple(e, target);
    }
});

function createRipple(event, target) {
    const ripple = document.createElement('div');
    ripple.classList.add('ripple');
    const rect = target.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
    ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
    target.appendChild(ripple);
    ripple.addEventListener('animationend', () => ripple.remove());
}

function initializeBackButtonReload() {
    window.addEventListener('pageshow', function(event) {
        if (event.persisted) {
            reloadIfNotReloaded();
        } else {
            const entries = performance.getEntriesByType('navigation');
            if (entries.length > 0 && entries[0].type === 'back_forward') {
                reloadIfNotReloaded();
            }
        }
    });

    function reloadIfNotReloaded() {
        if (!window.location.hash.includes('reloaded')) {
            window.location.reload();
            window.location.hash = 'reloaded';
        }
    }
}